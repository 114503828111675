import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  lang: any = localStorage.getItem('currentlang');

  constructor(
    private eventService: EventService
  ) {
    this.eventService.getLang().subscribe((val) => {
      this.lang = val || 'en';
    });
  }

  
  slideOption: OwlOptions = {
    loop: true,
    autoplay: false,
    mouseDrag: true,
    touchDrag: true,
    dots: false,
    nav: false,
    // autoplayTimeout: 2500,
    center:false,
    margin:15,
    responsive: {
      0: {
        items: 1.2,
        // margin:15,
      },
      400: {
        items: 1.2
      },
      768:{
        items: 1
      }

    },
  };
  langs(){
    let langlist = ['bg','cz','de','el','hu','jp','kr','pl','ru','sk','vn','id','pt','fr','tr','it','ro'];
    return langlist.includes(this.lang);
  }
}