import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafePipe } from './pipes/sanitizer.pipe';
import { OnlyNumber } from './directives/only-number.directive';
import { TwoDigitDecimaNumberDirective } from './directives/two-decimal-number.directive';
import { TrimAddressPipe } from './pipes/trim-address.pipe';

@NgModule({
  declarations: [
    SafePipe,
    TrimAddressPipe,
    OnlyNumber,
    TwoDigitDecimaNumberDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    SafePipe,
    TrimAddressPipe,
    OnlyNumber,
    TwoDigitDecimaNumberDirective
  ]
})
export class SharedModule { }
