import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../services/event.service';
import { verifyLanguage } from '../../shared/constants/app-enums';
// import { verifyLanguage } from '../../shared/app-enums';


@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent {
  lang: any;
  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private eventService: EventService
  ) {
    this.route.params.subscribe(params => {
      const lang = params['lang'];
      if (lang && verifyLanguage(lang)) {
        this.lang = lang;
      } else {
        const localLang = localStorage.getItem('currentlang');
        if (localLang && verifyLanguage(localLang)) {
          this.lang = localLang;
        } else {
          const userLang = navigator.language?.split('-')[0];
          this.lang = userLang && verifyLanguage(userLang) ? userLang : 'en';
        }
      }
    });
    
    this.translate.setDefaultLang('en');
    this.translate.use(this.lang);
    this.eventService.setLang(this.lang);
  }
}
