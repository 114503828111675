import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WalletConnectService } from 'src/app/services/wallet-connect.service';
import { Platform } from '@angular/cdk/platform';



@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss']
})

export class ConnectComponent implements OnInit {

  step: number = 0;
  isBsNetwork: boolean = false;
  isAndroid = false;
  isIos = false;

  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    private walletConnectService: WalletConnectService,
    private platform: Platform,
  ) { 
    this.isAndroid = this.platform.ANDROID;
    this.isIos = this.platform.IOS;
  }

  ngOnInit(): void {
  }

  connectWallet(mode: string): void {
    this.walletConnectService.connectWallet(mode, this.isBsNetwork);
    this.modalRef.hide();
  };

  openBestWallet(template: TemplateRef<any>) {
    this.modalRef.hide();
    setTimeout(()=> {

      this.modalRef = this.modalService.show(template, {
        class: 'modal-md modal-dialog-centered',
        backdrop: 'static',
      });
    }, 500)
  }
}
