import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LanguageComponent } from './components/language/language.component';
import { HomeComponent } from './components/home/home.component';


const routes: Routes = [
  {
    path: ':lang',
    component: LanguageComponent,
    children: [
      { path: '', component: HomeComponent },
    ]
  },
  { path: '**', redirectTo: '/en', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ anchorScrolling: 'enabled' }) , FormsModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
