<section class="about" id="about">
    <div class="container">
        <div class="wrapper">
        <img src="/assets/images/about-thread.svg" class="img-fluid about-thread d-block ">
        <div class="about-wrapper">
            <div class="about-frame">
                <div class="about-info">
                    <div class="title-wrapper">
                    <h2 class="mb-0" translate>about_title</h2>
                    <img src="/assets/images/svg-icons/logo.svg" class="img-fluid mx-auto d-block mb-4">
                </div>
                    <p translate>about_desc</p>
                </div>
            </div>
        </div>
    </div>
    </div>
</section>