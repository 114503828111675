import { Directive, ElementRef, HostListener, Input, OnInit, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appTwoDigitDecimalNumber]'
})

export class TwoDigitDecimaNumberDirective implements OnInit {

  @Input('noDecimal') noDecimal: number = 10;

  regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void{
    this.setRegex();
  }

  ngOnInit(): void {
    // console.log(this.noDecimal)
    this.setRegex();
  }

  setRegex(): any {
    if(this.noDecimal === 0 || !this.noDecimal){
      this.regex = new RegExp(/^[\d]*$/g);
    }else if(this.noDecimal === 6){
      this.regex = new RegExp(/^\d*\.?\d{0,6}$/g);
    }else if(this.noDecimal === 10){
      this.regex = new RegExp(/^\d*\.?\d{0,10}$/g);
    }
  }

  @HostListener('paste', ['$event']) onPaste($event: ClipboardEvent) {
    return false
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
