import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimAddress'
})
export class TrimAddressPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(value){
      const address = value.slice(0,6) + '...' + value.slice(value.length-5, value.length);
      return address;
    }else{
      return '--';
    }
  }

}
