import { Component } from '@angular/core';


@Component({
  selector: 'app-how-to-buy',
  templateUrl: './how-to-buy.component.html',
  styleUrls: [ './how-to-buy.component.scss']
})
export class HowToBuyComponent {

}
