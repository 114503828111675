import { Component } from '@angular/core';
import { EventService } from './services/event.service';
import { getParam } from './shared/constants/app-enums';

// import { getParam } from './shared/app-enums';
export var selectedLang: string;
declare global {
  interface Window {
    dataLayer: any[];
    solana: any;
    solflare: any;
    // coinbaseSolana: any;
    // trustWallet: any;
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss']
})

export class AppComponent {
  constructor(
    private eventService: EventService
  ) {
    getParam('clickId');
    getParam('source');
    getParam('tid');
    getParam('pid');
    this.eventService.getLang().subscribe((val) => {
      selectedLang = val || 'en';
    });
  }
}
