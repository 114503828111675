<app-header></app-header>

<div class="containerItem" id="home" [ngClass]="{'foreign-lang': langs()}">
  <img src="/assets/images/svg-icons/logo.svg" class="img-fuid mx-auto d-block d-md-none mob-logo" alt="">
  <div class="content">
    <div class="container">
    <div class="row justify-content-end">
      <div class="col-lg-5">
      <div class="text">
        <app-buy-widget></app-buy-widget>
      </div>
    </div>
    </div>
    </div>
  </div>
</div>
<app-about></app-about>
<app-how-to-buy></app-how-to-buy>