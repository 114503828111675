import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Location } from '@angular/common';
import { EventService } from '../../services/event.service';
import { selectedLang } from '../../app.component';
import { languages } from '../../shared/constants/app-enums';
import { WalletConnectService } from 'src/app/services/wallet-connect.service';
import { ConnectComponent } from 'src/app/modals/connect/connect.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements AfterViewInit {

  @ViewChild('navigation') navigation!: ElementRef;

  isScrolled: boolean = false;
  showMenu: boolean = false;
  openMenu: boolean = false;
  hashKey: string = '';
  windowSize: number;
  modalRef?: BsModalRef;

  currentLanguage: any;
  languagesList: Array<any> = languages;
  selectedLanguage: any;
  isShimmer: boolean = true;
  isMobile = (window.matchMedia && window.matchMedia('(max-device-width: 575px)').matches) || screen.width <= 575
  @HostListener('window:resize', ['$event'])

  navigations: Array<any> = [

    {
      routeName: 'how',
      navName: 'nav_how'
    },
    // {
    //   routeName:'platform',
    //   navName:'platform_head'
    // },
  ];

  constructor(
    private location: Location,
    private eventService: EventService,
    private translate: TranslateService,
    private walletConnectService: WalletConnectService,
    private modalService: BsModalService,
  ) {
    this.windowSize = window.screen.width;
    this.eventService.getLang().subscribe((val: any) => {
      this.currentLanguage = val || 'en';
      this.selectedLanguage = this.languagesList.filter(x => x.value === this.currentLanguage)[0];
    });

    this.selectedLanguage = this.languagesList.filter(x => x.value === this.currentLanguage)[0];
    setTimeout(() => this.isShimmer = false, 2000);
  }

  ngOnInit(): void {
    if (!this.currentLanguage) {
      this.currentLanguage = selectedLang;
      this.selectedLanguage = this.languagesList.filter(x => x.value === this.currentLanguage)[0];
    }
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isScrolled = window.pageYOffset >= 50;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowSize = event.target.innerWidth;
    this.isMobile = (window.matchMedia && window.matchMedia('(max-device-width: 575px)').matches) || screen.width <= 575
  }


  ngAfterViewInit(): void {
    if (window.location.hash) {
      setTimeout(() => {
        this.hashKey = window.location.hash.substring(1, window.location.hash.length);
        this.navigateToSection(this.hashKey);
        this.addActive(this.navigation.nativeElement);
      }, this.isShimmer ? 2500 : 500);
    }
  }

  changeLanguage(requestLang: any): void {
    this.currentLanguage = requestLang;
    this.translate.use(this.currentLanguage);
    this.eventService.setLang(this.currentLanguage);
    this.selectedLanguage = this.languagesList.filter(x => x.value === this.currentLanguage)[0];
    this.replaceURL();
  }

  replaceURL(): void {
    const current_url = this.location.path().split('/');
    current_url.shift()
    current_url.splice(0, 1, this.currentLanguage);
    const new_url = current_url.join('/');
    window.location.hash = this.hashKey;
    // window.location.pathname = '/' + new_url;
    this.location.replaceState("/" + new_url);
    console.log('current_url =', current_url);
    console.log('new_url =', new_url, this.hashKey);
  }

  navigateToSection(section: string) {
    this.hashKey = section;
    if (section) {
      window.location.hash = section;
      window.location.pathname = '/' + this.currentLanguage;
    }
  }

  addActive(event: any) {
    const target: any = event.currentTarget ? event.currentTarget : event;
    let linkList = target.parentNode.querySelectorAll('a');
    linkList.forEach((link: any) => {
      if (link === event.target || link.title === this.hashKey) {
        link.classList.add('active');
      } else {
        link.classList.remove('active');
      }
    });
  }

  openCloseMenu(): void {
    if (this.showMenu) {
      this.openMenu = !this.openMenu;
      setTimeout(() => (this.showMenu = !this.showMenu), 200);
    } else {
      this.showMenu = !this.showMenu;
      setTimeout(() => (this.openMenu = !this.openMenu), 200);
    }
  }

  get walletAddress(): any {
    return this.walletConnectService.walletAddress;
  }

  showWidget(section: string): void {
    if(this.isMobile){
      this.eventService.showBuyWidget(section);
      window.scroll({
        top: 300,
      });
    }
    else{
      this.eventService.showBuyWidget(section);
    }
  }

  connectModal(mode: string): void {
    this.modalRef = this.modalService.show(ConnectComponent, {
      class: 'modal-xs modal-dialog-centered',
      backdrop: 'static'
    });
  }

  disconnect(): void {
    this.walletConnectService.disConnectWallet();
  }

}
